<template>
  <div class="page-body">

    <div class="page-title-bar sticky">
      <h1>
        {{ title }}
        <span v-if="dataRef && dataSet.name">- {{ dataSet.name }}</span>
      </h1>

      <div class="btn-set">
        <button class="btn" @click="saveChanges()">Save</button>

        <router-link :to="{ name: 'Events' }" title="All Events" tag="button" class="btn btn-sec">
          <i class="fa-thin fa-angle-left"></i>
          Go Back
        </router-link>
      </div>
    </div>

    <p v-if="loading">Loading</p>

    <div class="body-flex" v-else>
      <div class="body-left three">

        <ValidationObserver ref="eventForm">
          <div class="event-form">
            <div class="form-section">
              <h3>Event Information</h3>
              <div class="form-group">
                <label for="eventName">Event Name<span class="req">*</span></label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input type="text" id="eventName" v-model="dataSet.name" />
                  <span class="invalid">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="checkbox-set">
                <div class="checkbox-group">
                  <label for="isSimpleEvent">
                    <input type="checkbox" v-model="isSimpleEvent" id="isSimpleEvent" />
                    This is a simple event
                    <i
                      class="fal fa-question-circle"
                      v-tooltip="'Simple events do not have shows or tickets for purchase'">
                    </i>
                  </label>
                </div>

                <div class="checkbox-group">
                  <label for="hasSeasonTickets">
                    <input type="checkbox" v-model="hasSeasonTickets" id="hasSeasonTickets" />
                    This event has season tickets
                  </label>
                </div>
              </div>
              

              <div v-if="!isSimpleEvent">
                <div class="form-group">
                  <label for="eventComic">Comic<span class="req">*</span></label>
                  <v-select
                    v-model="dataSet.comedian"
                    :options="comicList"
                    label="name"
                    id="eventComic">
                  </v-select>
                </div>

                <div class="checkbox-group">
                  <label for="showEventDescription">
                    <input type="checkbox" v-model="useBioForDescription" id="showEventDescription" />
                    Use Comic Biography as Event Description
                  </label>
                </div>
              </div>

              <div class="form-group" v-if="!useBioForDescription">
                <label for="eventDescription">Event Description</label>
                <wysiwyg-editor
                  id="eventDescription"
                  v-model="dataSet.description"
                  class="html-editor"
                  :api-key="tinyApi"
                  toolbar="undo redo | code | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl"
                  plugins="paste importcss searchreplace autolink code visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars"
                  :init="{
                    menubar: false,
                    setup(editor) {
                      editor.on('init', function() {
                        editor.setContent(editor.getContent());
                      });
                    },
                    content_css:'https://unpkg.com/tailwindcss@2.2.19/dist/tailwind.min.css'
                  }">
                </wysiwyg-editor>
              </div>
            </div>

            <div class="form-section">
              <h3>Event Schedule</h3>

              <div class="two-col-form">
                <div class="form-group">
                  <label for="startDate">Start Date<span class="req">*</span></label>
                  <validation-provider rules="required" mode="passive" v-slot="{ errors }">
                    <datetime type="date" value-zone="local" zone="local" v-model="dataSet.startDate" :auto="true" name="startDate" placeholder="mm-dd-yyyy"></datetime>
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="endDate">End Date<span class="req">*</span></label>
                  <validation-provider rules="required" mode="passive" v-slot="{ errors }">
                    <datetime type="date" value-zone="local" zone="local" v-model="dataSet.endDate" :min-datetime="dataSet.startDate" :auto="true" name="endDate" placeholder="mm-dd-yyyy"></datetime>
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </div>

              <div v-if="sortedShows.length > 0 && !isSimpleEvent">
                <h3>Shows</h3>
                <div class="content-panel shows-table">
                  <div class="panel-body no-padding">
                    <div class="inline-table">
                      <vue-good-table
                        :columns="showColumns"
                        :rows="sortedShows"
                        styleClass="vgt-table"
                        :sort-options="{
                          enabled: true,
                          initialSortBy: {field: 'date', type: 'asc'}
                        }"
                        :search-options="{ enabled: false }"
                        :pagination-options="{ enabled: false }"
                      >
                        <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field == 'date'">
                            {{ props.row.date | moment("ddd MMM Do @ h:mma") }}
                          </span>
                          <span v-else-if="props.column.field == 'actions'">
                            <div class="btn-set">
                              <button class="btn btn-sm btn-icon" @click.prevent="editShow(props.row.id)">
                                <i class="fal fa-edit"></i>
                              </button>
                              <button class="btn btn-sm btn-sec btn-icon btn-delete" @click="deleteShow(props.row.id)">
                                <i class="fal fa-trash-alt"></i>
                              </button>
                            </div>
                          </span>
                        </template>
                      </vue-good-table>
                    </div>
                  </div>
                </div>
              </div>

              <button v-if="!isSimpleEvent" class="btn btn-sec" @click="viewShowPanel()" title="Add a Show">Add a Show</button>

            </div>
          </div>
        </ValidationObserver>
      </div>

      <div class="body-right one">
        <div class="content-panel">
          <div class="panel-title">
            <h3>Thumb Image</h3>
          </div>
          <div class="panel-body">
            <file-uploader v-on:downloadURL="getThumbImgUrl" :parentContext="`${storageContext}/events/${dataSet.id}/`" :oldImgUrl="dataSet.imageUrl"></file-uploader>
          </div>
        </div>

        <div class="content-panel">
          <div class="panel-title">
            <h3>SEO Settings</h3>
          </div>
          <div class="panel-body">
            <div class="checkbox-group">
              <label for="featuredEvent">
                <input type="checkbox" v-model="dataSet.featured" id="featuredEvent" />
                Feature this Event
              </label>

              <label for="indexEvent" v-if="isWCL">
                <input type="checkbox" v-model="dataSet.index" id="indexEvent" />
                Index this Event Page
              </label>
            </div>

            <div class="form-group">
              <label for="seoTitle">Meta Title</label>
              <input type="text" id="seoTitle" v-model="dataSet.seoTitle" />
            </div>

            <div class="form-group">
              <label for="seoDescription">Meta Description</label>
              <textarea id="seoDescription" v-model="dataSet.seoDescription"></textarea>
            </div>
          </div>
        </div>

        <div class="content-panel" v-if="!isSimpleEvent">
          <div class="panel-title">
            <h3>Related Events</h3>
          </div>
          <div class="panel-body">
            <v-select
              v-model="dataSet.relatedEvents"
              :options="eventList"
              multiple
              label="name"
              id="relatedEvents">
            </v-select>
          </div>
        </div>

        <div class="link-bar" v-if="dataRef">
          <button class="btn btn-text btn-delete" @click="deleteData()" title="Delete">
            <i class="fa-thin fa-trash"></i>
            Delete Event
          </button>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import FileUploader from '@/components/FileUploader';
import { mapGetters } from 'vuex';
import EventShow from '@/components/EventShow.vue';
import { Datetime } from 'vue-datetime';
import PurgeApi from "@/services/admin/purgeApi";

export default {
  name: 'Event',
  data() {
    return {
      purgeApi: new PurgeApi(),
      dataRef: this.$route.params.ref,
      dataSet: {
        shows: []
      },
      useBioForDescription: false,
      isSimpleEvent: false,
      eventId: '',
      eventList: [],
      comicList: [],
      loading: false,
      newShow:{
        'id': this.makeid(30),
        'type': '',
        'startDateTime': '',
        'totalTickets': 0,
        'ticketPrice': 0,
        'serviceFee': 0,
        'taxRatePercentage': 0,
        'applyServiceFeePerItem': false
      },
      showColumns: [
        {
          label: 'Show Type',
          field: 'type',
          tdClass: 'title-col',
        },
        {
          label: 'Date',
          field: 'date'
        },
        {
          label: 'Price',
          field: 'ticketPrice'
        },
        {
          label: 'Regular Tickets',
          field: 'totalTickets'
        },
        {
          label: 'Season Tickets',
          field: 'seasonTickets'
        },
        {
          label: 'Tickets Sold',
          field: 'ticketsSold'
        },
        {
          label: '',
          field: 'actions',
          sortable: false,
          tdClass: 'actions-col',
        }

      ]
    }
  },
  metaInfo() {
    let self = this;
    return {
      title: self.title
    }
  },

  computed: {
    title: function() {
      let self = this;

      if(self.dataRef) {
        return 'Edit Event'
      } else {
        return 'Add an Event'
      }
    },

    sortedShows: function() {
      let self = this;
      let shows = self.dataSet.shows;
      if(shows) {
        return shows.sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime))
      } else {
        return []
      }
    },

    storageContext: function() {
      let self = this;

      return self.getCurrentPartner === 'watchcomedy.live' ? 'watchcomedylive' : `partners/${self.getCurrentPartner}`
    },

    ...mapGetters ({
      db: 'db',
      tinyApi: 'getTinyApi',
      isWCL: 'getIsWCL',
      getCurrentPartner: 'getCurrentPartner'
    })
  },

  components: {
    'FileUploader': FileUploader,
    Datetime: Datetime
  },

  async created() {
    let self = this;

    self.getData();
    self.eventId = JSON.stringify(Date.now());
  },

  methods: {
    getData: async function() {
      let self = this;
      self.loading = true;

      let eventsData = await self.db.collection('events').get();
      self.eventList = eventsData.docs.map(doc => doc.data());

      if(self.dataRef) {
        self.dataSet = self.eventList.find(event => event.id === self.dataRef);

        if(self.dataSet.useBioForDescription) { self.useBioForDescription = true; }
        if(self.dataSet.isSimpleEvent) { self.isSimpleEvent = true; }
        if(self.dataSet.hasSeasonTickets) { self.hasSeasonTickets = true; }
      }

      let comicsData = await self.db.collection('comedians').get();
      self.comicList = comicsData.docs.map(doc => doc.data());

      self.loading = false;
    },

    saveChanges: async function() {
      let self = this;
      let ref = self.dataRef ? self.dataRef : self.eventId;
      let success = await self.$refs.eventForm.validate();

      if(success) {

        delete self.dataSet.addShowEventHandler;
        self.dataSet.useBioForDescription = self.useBioForDescription;
        self.dataSet.isSimpleEvent = self.isSimpleEvent;
        self.dataSet.hasSeasonTickets = self.hasSeasonTickets || false;

        if(self.dataRef) {
          try {
            let event = self.dataSet;
            event.startDate = event.startDate.substr(0, 10);
            event.endDate = event.endDate.substr(0, 10);
            await self.db.collection('events').doc(ref).update(self.dataSet);
            self.$notify({
              group: 'saved',
              title: 'Event Saved',
              type: 'success'
            });
          }
          catch (error) {
            self.$notify({
              group: 'saved',
              title: 'Error Saving - ' + error,
              type: 'error'
            });
          }
        }
        else {
          try {
            let event = self.dataSet;
            event.id = ref;
            event.description = self.dataSet.description || '';
            event.startDate = event.startDate.substr(0, 10);
            event.endDate = event.endDate.substr(0, 10);
            await self.db.collection('events').doc(ref).set(event)
            self.$notify({
              group: 'saved',
              title: 'Event Successfully Created',
              type: 'success'
            });
            await self.$router.push({ path: '/events/' + ref });
            await self.getData();
          }
          catch (error) {
            self.$notify({
              group: 'saved',
              title: 'Error Creating Event - ' + error,
              type: 'error'
            });
          }
        }
        await self.purgeApi.purgeUrl(self.getCurrentPartner, `/events/${self.dataRef}`);
      }
    },

    deleteData: function() {
      let self = this;
      self.$modal.show('dialog', {
        title: 'Delete Confirmation',
        text: 'Are you sure you want to delete this event?',
        buttons: [
          {
            title: 'Confirm',
            class: 'btn dialogConfirmBtn',
            default: true,
            handler: async () => {
              let confirmButton = document.querySelector('.dialogConfirmBtn');
              confirmButton.innerHTML = '<i class="fa-thin fa-spinner-third fa-spin"></i>';

              self.db.collection('events').doc(self.dataRef).delete()
              .then(function() {
                  self.$notify({
                    group: 'saved',
                    title: 'Event Deleted',
                    type: 'success'
                  });
                  self.$modal.hide('dialog');
                  self.$router.push({ name: 'Events'} )
              })
              .catch(function(error) {
                  self.$notify({
                    group: 'saved',
                    title: 'Error Deleting - ' + ref,
                    type: 'error'
                  });
              });
            }
          },
          {
            title: 'Cancel',
            class: 'btn btn-sec',
            handler: () =>  { self.$modal.hide('dialog'); }
          }
        ]
      });
    },

    addShow(showData) {
      let self = this;

      self.newShow = showData;

      let show = {
        'id': self.makeid(30),
        'type': self.newShow.type,
        'date': self.newShow.date.substring(0,16),
        'ticketSetup': self.newShow.ticketSetup,
        'totalTickets': self.newShow.totalTickets ? parseInt(self.newShow.totalTickets) : 0,
        'seasonTickets': self.newShow.seasonTickets ? parseInt(self.newShow.seasonTickets) : 0,
        'minTickets': self.newShow.minTickets ? parseInt(self.newShow.minTickets) : 0,
        'maxTickets': self.newShow.maxTickets ? parseInt(self.newShow.maxTickets) : 0,
        'ticketPrice': self.newShow.ticketPrice ? parseFloat(self.newShow.ticketPrice) : 0,
        'serviceFee': self.newShow.serviceFee ? parseFloat(self.newShow.serviceFee) : 0,
        'taxRatePercentage': self.newShow.taxRatePercentage ? parseFloat(self.newShow.taxRatePercentage) : 0,
        'applyServiceFeePerItem': self.newShow.applyServiceFeePerItem ? self.newShow.applyServiceFeePerItem : false,
      };

      if (show.ticketSetup === 'order' || show.ticketSetup === 'Order Based') {
        show.orderRestrictions = self.newShow.orderRestrictions ? self.newShow.orderRestrictions : [];
        for(let restriction of show.orderRestrictions) {
          restriction.maxTickets = restriction.maxTickets ? parseInt(restriction.maxTickets) : 0;
          restriction.minTickets = restriction.minTickets ? parseInt(restriction.minTickets) : 0;
          restriction.totalOrders = restriction.totalOrders ? parseInt(restriction.totalOrders) : 0;
          show.totalTickets += (restriction.maxTickets * restriction.totalOrders);
        }
      }

      self.dataSet.shows.push(show);

      self.newShow = {}
      self.showFormErrors = false;
      self.saveReminder = true;
    },

    editShow: function(id) {
      let self = this;
      self.showPanel(EventShow, self.dataSet, id);
    },

    deleteShow: function(id) {
      let self = this;

      self.$modal.show('dialog', {
        title: 'Delete Confirmation',
        text: 'Are you sure you want to delete this show?',
        buttons: [
          {
            title: 'Confirm',
            class: 'btn dialogConfirmBtn',
            default: true,
            handler: () => {
              let confirmButton = document.querySelector('.dialogConfirmBtn');
              confirmButton.innerHTML = '<i class="fa-thin fa-spinner-third fa-spin"></i>';
              let showIndex = self.dataSet.shows.findIndex(show => show.id === id);
              self.dataSet.shows.splice(showIndex, 1);
              self.$modal.hide('dialog');
            }
          },
          {
            title: 'Cancel',
            class: 'btn btn-sec',
            handler: () =>  { self.$modal.hide('dialog'); }
          }
        ]
      });
    },

    getThumbImgUrl (url) {
      let self = this;
      self.dataSet.imageUrl = url
    },

    getHeaderImgUrl (url) {
      let self = this;
      self.dataSet.headerImageUrl = url
    },

    viewShowPanel: function() {
      let self = this;
      self.dataSet.addShowEventHandler = self.addShow;
      self.showPanel(EventShow, self.dataSet);
    },

    showPanel: function(component, flyoutData, showId, closeCallback, panelProperties) {
      let self = this;

      self.panelResult = self.$showPanel({
        'component': component,
        props: {
          flyoutData,
          showId
        },
        openOn: 'right',
        disableBgClick: false,
        ...panelProperties
      });

      self.panelResult.promise
      .then(result => {
        if(closeCallback) {
          closeCallback(result);
        }
      });
    },

    closePanel() {
        this.panelResult.hide();
    },

    makeid: function(length) {
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
    }
  },

  watch: {
    'db': function() {
      let self = this;
      self.$router.push({ name: 'Events' });

    }
  }
}
</script>
