var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-body"},[_c('div',{staticClass:"page-title-bar sticky"},[_c('h1',[_vm._v(_vm._s(_vm.title))]),_c('button',{staticClass:"btn",attrs:{"title":"Save Changes"},on:{"click":function($event){return _vm.updateSettings()}}},[_vm._v("Save Changes")])]),(_vm.loading)?_c('p',[_vm._v("Loading")]):_vm._e(),(!_vm.loading)?_c('div',[_c('ValidationObserver',{ref:"settingsForm"},[_c('ul',{staticClass:"form-tabs"},_vm._l((_vm.formTabs),function(tab,tabIndex){return _c('li',{key:tabIndex,class:{ 'active' : _vm.currentTab === tab},on:{"click":function($event){_vm.currentTab = tab}}},[_c('h2',[_vm._v(_vm._s(tab))])])}),0),_c('div',{staticClass:"settings-form"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 'Ticket Sales'),expression:"currentTab === 'Ticket Sales'"}],staticClass:"content-panel"},[_c('div',{staticClass:"panel-title"},[_c('h3',[_vm._v("Ticket Confirmation Page")])]),_c('div',{staticClass:"panel-body p-margin"},[_c('p',[_vm._v("Custom content to be displayed at the bottom of the "),_c('strong',[_vm._v("Ticket Purchase Confirmation Page / Email")])]),_c('div',{staticClass:"form-group"},[_c('wysiwyg-editor',{staticClass:"html-editor",attrs:{"api-key":_vm.tinyApi,"toolbar":"undo redo | bold italic underline strikethrough code | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl","plugins":"paste importcss searchreplace autolink code visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars","init":{
                  menubar: false,
                  setup(editor) {
                    editor.on('init', function() {
                      editor.setContent(editor.getContent());
                    });
                  },
                  content_css:'https://unpkg.com/tailwindcss@2.2.19/dist/tailwind.min.css'
                }},model:{value:(_vm.messaging.tickets.confirmation),callback:function ($$v) {_vm.$set(_vm.messaging.tickets, "confirmation", $$v)},expression:"messaging.tickets.confirmation"}})],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 'Product Sales'),expression:"currentTab === 'Product Sales'"}],staticClass:"content-panel"},[_c('div',{staticClass:"panel-title"},[_c('h3',[_vm._v("Product Confirmation Page")])]),_c('div',{staticClass:"panel-body p-margin"},[_c('p',[_vm._v("Custom content to be displayed at the bottom of the "),_c('strong',[_vm._v("Product Purchase Confirmation Page / Email")])]),_c('div',{staticClass:"form-group"},[_c('wysiwyg-editor',{staticClass:"html-editor",attrs:{"api-key":_vm.tinyApi,"toolbar":"undo redo | bold italic underline strikethrough code | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl","plugins":"paste importcss searchreplace autolink code visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars","init":{
                  menubar: false,
                  setup(editor) {
                    editor.on('init', function() {
                      editor.setContent(editor.getContent());
                    });
                  },
                  content_css:'https://unpkg.com/tailwindcss@2.2.19/dist/tailwind.min.css'
                }},model:{value:(_vm.messaging.products.confirmation),callback:function ($$v) {_vm.$set(_vm.messaging.products, "confirmation", $$v)},expression:"messaging.products.confirmation"}})],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 'Season Tickets'),expression:"currentTab === 'Season Tickets'"}],staticClass:"content-panel"},[_c('div',{staticClass:"panel-title"},[_c('h3',[_vm._v("Season Ticket Confirmation Page / Email")])]),_c('div',{staticClass:"panel-body p-margin"},[_c('p',[_vm._v("Custom content to be displayed at the bottom of the "),_c('strong',[_vm._v("Season Ticket Purchase Confirmation Page / Email")])]),_c('div',{staticClass:"form-group"},[_c('wysiwyg-editor',{staticClass:"html-editor",attrs:{"api-key":_vm.tinyApi,"toolbar":"undo redo | bold italic underline strikethrough code | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl","plugins":"paste importcss searchreplace autolink code visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars","init":{
                  menubar: false,
                  setup(editor) {
                    editor.on('init', function() {
                      editor.setContent(editor.getContent());
                    });
                  },
                  content_css:'https://unpkg.com/tailwindcss@2.2.19/dist/tailwind.min.css'
                }},model:{value:(_vm.messaging.seasonTickets.confirmation),callback:function ($$v) {_vm.$set(_vm.messaging.seasonTickets, "confirmation", $$v)},expression:"messaging.seasonTickets.confirmation"}})],1)])])])])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }