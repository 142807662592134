import Vue from 'vue'
import VueRouter from 'vue-router'

/* General */
import Dashboard from '@/views/Dashboard.vue'
import Login from '@/views/Login.vue'
import Logout from '@/views/Logout.vue'
import {auth} from "@/firebaseConfig";

/* Primary */
import Events from '@/views/Events.vue'
import Event from '@/views/Event.vue'
import Coupons from '@/views/Coupons.vue'
import Coupon from '@/views/Coupon.vue'
import Comics from '@/views/Comics.vue'
import Comic from '@/views/Comic.vue'
import Products from '@/views/Products.vue'
import Product from '@/views/Product.vue'
import Venues from '@/views/Venues.vue'
import Venue from '@/views/Venue.vue'

/* Sales */
import Orders from '@/views/orders/Orders.vue'
import Order from '@/views/orders/Order.vue'
import ProductOrders from '@/views/orders/ProductOrders.vue'
import ProductOrder from '@/views/orders/ProductOrder.vue'
import Reports from '@/views/orders/Reports.vue'

/* Season Tickets */
import SeasonTicketHolders from '@/views/season-tickets/SeasonTicketHolders.vue'
import SeasonTicketHolder from '@/views/season-tickets/SeasonTicketHolder.vue'
import SeasonTicketReservations from '@/views/season-tickets/SeasonTicketReservations.vue'
import SeasonTicketReservation from '@/views/season-tickets/SeasonTicketReservation.vue'

/* Website */
import Articles from '@/views/website/Articles.vue'
import Article from '@/views/website/Article.vue'
import Promotions from '@/views/website/Promotions.vue'
import Promotion from '@/views/website/Promotion.vue'
import Pages from '@/views/website/Pages.vue'
import Page from '@/views/website/Page.vue'
import Display from '@/views/website/Display.vue'
import Menus from '@/views/website/Menus.vue'
import Messaging from '@/views/website/Messaging.vue'

/* Customers */
import InsiderEmails from '@/views/customers/InsiderEmails.vue'
import SupportRequests from '@/views/customers/SupportRequests.vue'

/* Marketing */
import SocialMedia from '@/views/marketing/Social.vue'

/* Settings */
import Users from '@/views/settings/Users.vue'
import User from '@/views/settings/User.vue'
import Profile from '@/views/settings/Profile.vue'
import VenueInfo from '@/views/settings/VenueInfo.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: () => '/dashboard'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/admin',
    redirect: Dashboard
  },
  {
    path: '/events',
    name: 'Events',
    component: Events,
  },
  {
    path: '/events/add',
    name: 'AddEvent',
    component: Event
  },
  {
    path: '/events/:ref',
    name: 'EditEvent',
    component: Event
  },
  {
    path: '/coupons',
    name: 'Coupons',
    component: Coupons,
  },
  {
    path: '/coupons/add',
    name: 'AddCoupon',
    component: Coupon
  },
  {
    path: '/coupons/:ref',
    name: 'EditCoupon',
    component: Coupon
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
  },
  {
    path: '/products/add',
    name: 'AddProduct',
    component: Product
  },
  {
    path: '/products/:ref',
    name: 'EditProduct',
    component: Product
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
  },
  {
    path: '/orders/add',
    name: 'AddOrder',
    component: Order
  },
  {
    path: '/orders/:ref',
    name: 'EditOrder',
    component: Order
  },
  {
    path: '/season-ticket-holders',
    name: 'SeasonTicketHolders',
    component: SeasonTicketHolders,
  },
  {
    path: '/season-ticket-holders/:ref',
    name: 'EditSeasonTicketHolder',
    component: SeasonTicketHolder,
  },
  {
    path: '/season-ticket-reservations',
    name: 'SeasonTicketReservations',
    component: SeasonTicketReservations,
  },
  {
    path: '/season-ticket-reservations/add',
    name: 'AddSeasonTicketReservation',
    component: SeasonTicketReservation
  },
  {
    path: '/season-ticket-reservations/:ref',
    name: 'EditSeasonTicketReservation',
    component: SeasonTicketReservation
  },
  {
    path: '/product-orders',
    name: 'ProductOrders',
    component: ProductOrders,
  },
  {
    path: '/product-orders/add',
    name: 'AddProductOrder',
    component: ProductOrder
  },
  {
    path: '/product-orders/:ref',
    name: 'EditProductOrder',
    component: ProductOrder
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
  },
  {
    path: '/comics',
    name: 'Comics',
    component: Comics,
  },
  {
    path: '/comics/add',
    name: 'AddComic',
    component: Comic,
  },
  {
    path: '/comics/:ref',
    name: 'EditComic',
    component: Comic
  },
  {
    path: '/venues',
    name: 'Venues',
    component: Venues,
  },
  {
    path: '/venues/add',
    name: 'AddVenue',
    component: Venue
  },
  {
    path: '/venues/:ref',
    name: 'EditVenue',
    component: Venue
  },
  {
    path: '/website/articles',
    name: 'Articles',
    component: Articles,
  },
  {
    path: '/website/articles/add',
    name: 'AddArticle',
    component: Article
  },
  {
    path: '/website/articles/:ref',
    name: 'EditArticle',
    component: Article
  },
  {
    path: '/website/pages',
    name: 'Pages',
    component: Pages,
  },
  {
    path: '/website/pages/add',
    name: 'AddPage',
    component: Page
  },
  {
    path: '/website/pages/:ref',
    name: 'EditPage',
    component: Page
  },
  {
    path: '/website/promotions',
    name: 'Promotions',
    component: Promotions,
  },
  {
    path: '/website/promotions/add',
    name: 'AddPromotion',
    component: Promotion
  },
  {
    path: '/website/promotions/:ref',
    name: 'EditPromotion',
    component: Promotion
  },
  {
    path: '/website/display',
    name: 'Display',
    component: Display
  },
  {
    path: '/website/menus',
    name: 'Menus',
    component: Menus
  },
  {
    path: '/website/messaging',
    name: 'Messaging',
    component: Messaging
  },
  {
    path: '/customers/email',
    name: 'InsiderEmails',
    component: InsiderEmails
  },
  {
    path: '/customers/support-requests',
    name: 'SupportRequests',
    component: SupportRequests
  },
  {
    path: '/marketing/social',
    name: 'SocialMedia',
    component: SocialMedia
  },
  {
    path: '/settings/users',
    name: 'Users',
    component: Users,
  },
  {
    path: '/settings/users/add',
    name: 'AddUser',
    component: User,
  },
  {
    path: '/settings/users/:ref',
    name: 'EditUser',
    component: User,
  },
  {
    path: '/settings/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/settings/venue-info',
    name: 'VenueInfo',
    component: VenueInfo,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.name !== 'Login';
  const currentUser = auth.currentUser;

  if (requiresAuth && !currentUser) next({ path: '/login', query: { redirect: to.fullPath } });
  else if (!requiresAuth && currentUser) next('/');
  else next();
});

export default router;
