<template>
    <div class="content-panel show-panel flyout">
        <div class="panel-title">
            <h3>{{ title }}</h3>
            <a href="javascript:" @click="closeCancel()" title="Close Panel"><i class="close-flyout fal fa-times"></i></a>
        </div>

        <div class="panel-body">
            <form @submit.prevent="saveShow()">
                <ValidationObserver ref="showForm">
                    <div class="two-col-form">

                        <div class="form-group">
                            <label for="showType">Show Name<span class="req">*</span></label>
                            <validation-provider rules="required" v-slot="{ errors }">
                                <input type="text" id="showType" name="Show Name" v-model="show.type" />
                                <span class="invalid">{{ errors[0] }}</span>
                            </validation-provider>
                        </div>

                        <div class="form-group">
                            <label for="showDate">Start Date &amp; Time<span class="req">*</span></label>
                            <validation-provider rules="required" mode="passive" v-slot="{ errors }">
                                <datetime
                                    id="showDate"
                                    type="datetime"
                                    value-zone="local"
                                    zone="local"
                                    :min-datetime="flyoutData.startDate"
                                    :max-datetime="$moment(flyoutData.endDate).add(1, 'day').add(1, 'second').format()"
                                    :minute-step="15"
                                    :use12-hour="true"
                                    :auto="true"
                                    v-model="show.date"
                                    name="Show Start Date / Time"
                                    placeholder="mm-dd-yyyy">
                                </datetime>
                                <span class="invalid">{{ errors[0] }}</span>
                            </validation-provider>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="ticketPrice">Ticket Price<span class="req">*</span></label>
                        <validation-provider rules="required" v-slot="{ errors }">
                            <input type="number" id="ticketPrice" name="Ticket Price" v-model="show.ticketPrice" />
                            <span class="invalid">{{ errors[0] }}</span>
                        </validation-provider>
                    </div>

                    <div class="two-col-form">
                        <div class="form-group">
                            <label for="fieldServiceFee">Service Fee: ($)</label>
                            <input type="number" id="fieldServiceFee" name="Service Fee" v-model="show.serviceFee">

                            <div class="checkbox-group">
                                <label for="applyPerItem">
                                    <input type="checkbox" id="applyPerItem" v-model="show.applyServiceFeePerItem" />
                                    Apply Service Fee For Each Item
                                </label>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="fieldTaxRate">Tax Rate: (%)</label>
                            <input type="number" id="fieldTaxRate" name="Tax Rate" v-model="show.taxRatePercentage">
                        </div>
                    </div>

                    <h4>Sell Tickets Based on Total Number of Tickets or Total Orders?</h4>

                    <div class="form-group">
                        <validation-provider rules="required" v-slot="{ errors }">
                            <v-select
                                :reduce="item => item.value"
                                label="text"
                                v-model="show.ticketSetup"
                                name="Ticket Setup"
                                placeholder="- Select a Ticket Setup -"
                                :options="[{text: 'Ticket Based', value: 'ticket'}, {text: 'Order Based', value: 'order'} ]">
                            </v-select>
                            <span class="invalid">{{ errors[0] }}</span>
                        </validation-provider>
                    </div>

                    <div class="order-type-fields" v-if="show.ticketSetup === 'ticket'">
                        <h4>Set Ticket Availability</h4>

                        <div class="form-group">
                            <label for="totalTickets">Number of Regular Tickets<span class="req">*</span></label>
                            <validation-provider rules="required" v-slot="{ errors }">
                                <input type="number" id="totalTickets" v-model="show.totalTickets" />
                                <span class="invalid">{{ errors[0] }}</span>
                            </validation-provider>
                        </div>

                        <div class="form-group">
                            <label for="seasonTickets">Number of Season Tickets<span class="req">*</span></label>
                            <validation-provider rules="required" v-slot="{ errors }">
                                <input type="number" id="seasonTickets" v-model="show.seasonTickets" />
                                <span class="invalid">{{ errors[0] }}</span>
                            </validation-provider>
                        </div>

                        <div class="two-col-form">
                            <div class="form-group">
                                <label for="">Minimum Tickets Per Order</label>
                                <input type="number" v-model="show.minTickets" />
                            </div>

                            <div class="form-group">
                                <label for="">Maximum Tickets Per Order</label>
                                <input type="number" v-model="show.maxTickets" />
                            </div>
                        </div>
                    </div>

                </ValidationObserver>

                <div class="order-type-fields" v-show="show.ticketSetup === 'order'">
                    <ValidationObserver ref="restrictionForm">
                        <form @submit.prevent="addOrderRestriction()">
                            <div class="table-responsive restrictions-table" v-if="show.orderRestrictions && show.orderRestrictions.length">
                                <h4>All Order Restrictions</h4>

                                <table>
                                    <thead>
                                    <tr>
                                        <th>Available Orders</th>
                                        <th>Min Tickets Per Order</th>
                                        <th>Max Tickets Per Order</th>
                                        <th v-if="isCurrentShow">Confirmed Orders</th>
                                        <th v-if="isCurrentShow">Tickets Sold</th>
                                        <th align="right"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(order, index) in show.orderRestrictions" :key="index">
                                        <td>{{ order.totalOrders }}</td>
                                        <td>{{ order.minTickets }}</td>
                                        <td>{{ order.maxTickets }}</td>
                                        <td v-if="isCurrentShow">{{ order.ordersSold }}</td>
                                        <td v-if="isCurrentShow">{{ order.ticketsSold }}</td>
                                        <td class="btn-set" align="right">
                                            <button class="btn btn-sm btn-sec" @click="deleteOrderRestriction(index)">Delete</button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="new-restriction">
                                <h4>Add an Order Restriction</h4>

                                <div class="three-col-form">
                                    <div class="form-group">
                                        <label for="totalOrders">Total Available Orders<span class="req">*</span></label>
                                        <validation-provider rules="required" mode="passive" v-slot="{ errors }">
                                            <input type="number" id="totalOrders" v-model="newRestriction.totalOrders" />
                                            <span class="invalid">{{ errors[0] }}</span>
                                        </validation-provider>
                                    </div>

                                    <div class="form-group">
                                        <label for="minTickets">Minimum Tickets Per Order<span class="req">*</span></label>
                                        <validation-provider rules="required" mode="passive" v-slot="{ errors }">
                                            <input type="number" id="minTickets" v-model="newRestriction.minTickets" />
                                            <span class="invalid">{{ errors[0] }}</span>
                                        </validation-provider>
                                    </div>

                                    <div class="form-group">
                                        <label for="maxTickets">Maximum Tickets Per Order<span class="req">*</span></label>
                                        <validation-provider rules="required" mode="passive" v-slot="{ errors }">
                                            <input type="number" id="maxTickets" v-model="newRestriction.maxTickets" />
                                            <span class="invalid">{{ errors[0] }}</span>
                                        </validation-provider>
                                    </div>
                                </div>

                                <button class="btn btn-sm" type="submit" title="Add Order Restriction">Add Order Restriction</button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </form>
        </div>

        <div class="panel-footer btn-set">
            <button class="btn btn-lg" @click="saveShow()" title="Save">{{ ctaLabel }}</button>
            <button class="btn btn-text" @click="closeCancel()" title="Cancel">Cancel</button>
        </div>
    </div>
</template>

<script>
import { Datetime } from 'vue-datetime'

export default {
    name: 'EventShow',
    data() {
        return {
            show: {
                orderRestrictions: []
            },
            showFormErrors: false,
            newRestriction: {},
            isCurrentShow: false
        }
    },
    props: {
        flyoutData: Object,
        showId: String
    },
    components: {
        Datetime: Datetime
    },
    computed: {
        title: function() {
            let self = this;
            if(self.isCurrentShow) {
                return 'Edit Show'
            } else {
                return 'Add a New Show'
            }
        },

        ctaLabel: function() {
            let self = this;
            if(self.isCurrentShow) {
                return 'Update Show'
            } else {
                return 'Create Show'
            }
        }
    },
    methods: {
        saveShow: async function() {
            let self = this;
            let success = await self.$refs.showForm.validate();

            if (success) {
                if(!self.isCurrentShow) {
                    self.flyoutData.addShowEventHandler(self.show);
                }
                else {
                  let newShow = self.show;
                  newShow.totalTickets = newShow.totalTickets ? parseInt(newShow.totalTickets) : 0;
                  newShow.seasonTickets = newShow.seasonTickets ? parseInt(newShow.seasonTickets) : 0;
                  newShow.minTickets = newShow.minTickets ? parseInt(newShow.minTickets) : 0;
                  newShow.maxTickets = newShow.maxTickets ? parseInt(newShow.maxTickets) : 0;
                  newShow.ticketPrice = newShow.ticketPrice ? parseFloat(newShow.ticketPrice) : 0;
                  newShow.serviceFee = newShow.serviceFee ? parseFloat(newShow.serviceFee) : 0;
                  newShow.taxRatePercentage = newShow.taxRatePercentage ? parseFloat(newShow.taxRatePercentage) : 0;
                  if ((newShow.ticketSetup === 'order' || newShow.ticketSetup === 'Order Based') && newShow.orderRestrictions && Array.isArray(newShow.orderRestrictions)) {
                    newShow.totalTickets = 0;
                    for(let restriction of newShow.orderRestrictions) {
                      restriction.maxTickets = restriction.maxTickets ? parseInt(restriction.maxTickets) : 0;
                      restriction.minTickets = restriction.minTickets ? parseInt(restriction.minTickets) : 0;
                      restriction.totalOrders = restriction.totalOrders ? parseInt(restriction.totalOrders) : 0;
                      newShow.totalTickets += (restriction.maxTickets * restriction.totalOrders);
                    }
                  }
                }
                if(self.show.ticketSetup === 'ticket' || self.show.ticketSetup === 'Ticket Based') {
                    delete self.show.orderRestrictions;
                }
                self.$emit('closePanel', {});
            } else {
                self.showFormErrors = true
            }

        },

        addOrderRestriction: async function() {
            let self = this;
            let success = await self.$refs.restrictionForm.validate();
            if (success) {
                self.show.orderRestrictions.push(self.newRestriction);
                self.newRestriction = {};
            } else {
                self.showFormErrors = true
            }
        },

        deleteOrderRestriction: function(index) {
            let self = this;
            if (confirm('Are you sure you want to delete?')) {
                self.show.orderRestrictions.splice(index, 1)
            }
        },

        closeCancel: function() {
            let self = this;
            self.$emit('closePanel', {});
        }
    },


    mounted() {
        let self = this;
        
        if(self.showId) {
            self.isCurrentShow = true;

            self.show = self.flyoutData.shows.find(show => show.id === self.showId);

            if(!self.show.orderRestrictions) {
                self.show.orderRestrictions = []
            }

            if(self.show.ticketSetup === 'Ticket Based') {
                self.show.ticketSetup = 'ticket'
            }

            if(self.show.ticketSetup === 'Order Based') {
                self.show.ticketSetup = 'order'
            }
        }
    },

    watch: {
        'show.date': function (value) {
            let self = this;
            self.show.date = value.substr(0, 16);
        }
    }
}
</script>
