<template>
  <div class="page-body">

    <div class="page-title-bar sticky">
      <h1>
        {{ title }}
      </h1>

      <div class="btn-set">
        <button class="btn" @click="saveChanges()" :disabled="saving">
          <span v-if="!saving">Save</span>
          <span v-else><i class="fal fa-spinner-third fa-spin"></i></span>
        </button>

        <router-link :to="{ name: 'SeasonTicketHolders' }" title="All Orders" tag="button" class="btn btn-sec">
          <i class="fa-thin fa-angle-left"></i>
          Go Back
        </router-link>
      </div>
    </div>

    <p v-if="loading">Loading</p>

    <div class="body-flex" v-else>
      <div class="body-left three">

        <ValidationObserver ref="seasonTicketHolderForm">
          <div class="data-form">
            <h2>Season Ticket Holder Information</h2>

            <div class="form-section">
              <div class="two-col-form">
                <div class="form-group">
                  <label for="customerFirstName">Name<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input type="text" id="customerFirstName" v-model="dataSet.name" />
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="emailAddress">Email Address<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input type="text" id="emailAddress" v-model="dataSet.email" disabled />
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </div>
            </div>

            <h2>
              Season Ticket Packages
            </h2>

            <div class="form-section">
              <ul class="package-list">
                <li v-for="(item, index) in dataSet.packages">
                  <h4>{{ item.packageName }}</h4>
                  <p>Package ID: {{ item.packageId }}</p>
                  <p>
                    Product Order ID: 
                    <router-link :to="`/product-orders/${item.seasonTicketOrderId}`" :title="item.seasonTicketOrderId">{{ item.seasonTicketOrderId }}</router-link>
                  </p>

                  <p class="delete-package">
                    <a
                      href="javascript:void(0)" 
                      @click="deletePackage(index)">
                      Delete Package
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </ValidationObserver>
      </div>

      <div class="body-right one">
        <div class="content-panel">
          <div class="panel-title">
            <h3>Customer Notes</h3>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <label for="orderNotes">Add any notes specific to this customer:</label>
              <textarea id="orderNotes" v-model="dataSet.customerNotes"></textarea>
            </div>
          </div>
        </div>

        <!-- Delete Order -->
        <!-- <div class="link-bar" v-if="dataRef">
          <button class="btn btn-text btn-delete" @click="deleteData()" title="Delete">
            <i class="fa-thin fa-trash"></i>
            Delete Order
          </button>
        </div> -->
      </div>
    </div>


  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import {auth, updateEmail} from "@/firebaseConfig";

export default {
  name: 'SeasonTicketHolder',
  data() {
    return {
      dataRef: this.$route.params.ref,
      seasonTicketHolder: {},
      dataSet: {
        email: '',
        name: '',
        customerNotes: ''
      },
      newDate: new Date().toISOString(),
      loading: false,
      saving: false
    }
  },
  metaInfo() {
    let self = this;
    return {
      title: self.title
    }
  },

  computed: {
    title: function() {
      return 'Edit Season Ticket Holder'
    },

    ...mapGetters ([
      'db'
    ])
  },

  components: {

  },

  async mounted() {
    let self = this;
    self.loading = true;
    await self.getSeasonTicketHolder();
    self.loading = false;
  },

  methods: {
    notifyCustomer: async function (partnerId, orderId) {
      let self = this;
      await self.emailApi.sendProductOrderEmail(partnerId, orderId);

      self.$notify({
        group: 'saved',
        title: 'Customer Successfully Notified',
        type: 'success'
      });
    },


    getSeasonTicketHolder: async function() {
      let self = this;

      let userData = await self.db.collection('userProfiles').doc(self.dataRef).get();
      self.seasonTicketHolder = userData.data();

      if(self.seasonTicketHolder) {
        self.dataSet = {
          email: self.seasonTicketHolder.email,
          name: self.seasonTicketHolder.name,
          customerNotes: self.seasonTicketHolder.customerNotes || '',
          packages: self.seasonTicketHolder.packages || []
        }
      }
    },

    deletePackage: function(index) {
      let self = this;
      self.dataSet.packages.splice(index, 1);
    },

    // updateUserEmail: async function() {
    //   let self = this;
    //   const currentUser = auth.currentUser;
    //   await currentUser.updateEmail(self.dataSet.email);
    // },

    saveChanges: async function() {
      let self = this;

      let success = await self.$refs.seasonTicketHolderForm.validate();
      let ref = self.dataRef;

      if(!ref) {
        alert('No Order ID');
        return;
      }

      if(success) {
        self.saving = true;
        try {
          // await self.updateUserEmail();
          await self.db.collection('userProfiles').doc(ref).update(self.dataSet);
          self.saving = false;
          self.$notify({
            group: 'saved',
            title: 'Order Saved',
            type: 'success'
          });
        }
        catch (error) {
          self.saving = false;
          self.$notify({
            group: 'saved',
            title: 'Error Saving - ' + error,
            type: 'error'
          });
        }
      }
    },

    deleteData: function() {
      let self = this;
      self.$modal.show('dialog', {
        title: 'Delete Confirmation',
        text: 'Are you sure you want to delete this order?',
        buttons: [
          {
            title: 'Confirm',
            class: 'btn dialogConfirmBtn',
            default: true,
            handler: async () => {
              let confirmButton = document.querySelector('.dialogConfirmBtn');
              confirmButton.innerHTML = '<i class="fa-thin fa-spinner-third fa-spin"></i>';

              self.db.collection('productOrders').doc(self.dataRef).delete()
              .then(function() {
                  self.$notify({
                    group: 'saved',
                    title: 'Order Deleted',
                    type: 'success'
                  });
                  self.$modal.hide('dialog');
                  self.$router.push({ name: 'ProductOrders'} )
              })
              .catch(function(error) {
                  self.$notify({
                    group: 'saved',
                    title: 'Error Deleting - ' + ref,
                    type: 'error'
                  });
              });
            }
          },
          {
            title: 'Cancel',
            class: 'btn btn-sec',
            handler: () =>  { self.$modal.hide('dialog'); }
          }
        ]
      });
    }
  },

  watch: {
    'db': function() {
      let self = this;
      self.$router.push({ name: 'SeasonTicketHolders' });
    },

  }
}
</script>

<style scoped>
ul.package-list {
  list-style-type: none;
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 50px;
  row-gap: 20px;
}

ul.package-list li {
  border-bottom: 1px solid #ccc;
  padding: 20px;
  background-color: #fff;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
  border-radius: 7px;
  margin-bottom: 10px;
}

ul.package-list li h4 {
  margin: 0 0 7px 0;
}

ul.package-list li p {
  margin: 0 0 5px 0;
  color: #666;
}

.delete-package {
  padding-top:10px;
}

.delete-package a {
  color: #c7102e;
  cursor: pointer;
}
</style>