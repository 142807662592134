<template>
  <div class="page-body">
    <div class="page-title-bar sticky">
      <h1>
        {{ title }}
        <span v-if="dataRef && dataSet.code">- {{ dataSet.code }}</span>
      </h1>

      <div class="btn-set">
        <button class="btn" @click="saveChanges()">Save</button>

        <router-link :to="{ name: 'Coupons' }" title="All Coupons" tag="button" class="btn btn-sec">
          <i class="fa-thin fa-angle-left"></i>
          Go Back
        </router-link>
      </div>
    </div>

    <p v-if="loading">Loading</p>

    <div class="body-flex" v-else>
      <div class="body-left three">

        <ValidationObserver ref="couponForm">
          <div class="coupon-form">
            <div class="form-section">
              <div class="form-group">
                <label for="code">Code<span class="req">*</span></label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input type="text" id="code" v-model="dataSet.code" />
                  <span class="invalid">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="usageType">Usage Type<span class="req">*</span></label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-select
                    v-model="dataSet.usage"
                    :options="usageTypes"
                    label="label"
                    :reduce="type => type.value"
                    id="usageType">
                  </v-select>
                  <span class="invalid">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="couponDescription">Coupon Description</label>
                <wysiwyg-editor
                  id="couponDescription"
                  v-model="dataSet.description"
                  class="html-editor short"
                  :api-key="tinyApi"
                  toolbar="undo redo | code | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl"
                  plugins="paste importcss searchreplace autolink code visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars"
                  :init="{
                    menubar: false,
                    setup(editor) {
                      editor.on('init', function() {
                        editor.setContent(editor.getContent());
                      });
                    },
                    content_css:'https://unpkg.com/tailwindcss@2.2.19/dist/tailwind.min.css'
                  }">
                </wysiwyg-editor>
              </div>

              <div class="form-group">
                <label for="eventSpecific">Is this coupon only valid for a specific event?</label>
                <v-select
                  @input="getShowList"
                  v-model="dataSet.event"
                  :options="eventList"
                  label="name"
                  value="id"
                  :reduce="event => {
                    return {
                      id: event.id,
                      name: event.name
                    }
                  }"
                  id="eventSpecific">
                </v-select>
              </div>   
              
              <div class="form-group" v-if="dataSet.event">
                <label for="showSpecific">Is this coupon only valid for a specific show?</label>
                <v-select
                  v-model="dataSet.show"
                  :options="showList"
                  label="date"
                  value="id"
                  :reduce="show => {
                    return {
                      id: show.id,
                      date: show.date
                    }
                  }"
                  id="showSpecific">
                </v-select>
              </div>   
              
              <div class="two-col-form">
                <div class="form-group">
                  <label for="minPurchaseAmount">Minimum Purchase Amount</label>
                  <input type="number" id="minPurchaseAmount" name="Minimum Purchase" v-model="dataSet.minPurchase" />
                </div>

                <div class="form-group">
                  <label for="minPurchaseTypes">Minimum Purchase Type</label>
                  <v-select
                    v-model="dataSet.minPurchaseType"
                    :options="minPurchaseTypes"
                    label="label"
                    :reduce="type => type.value"
                    id="minPurchaseTypes">
                  </v-select>
                </div>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>

      <div class="body-right one">

        <div class="content-panel">
          <div class="panel-title">
            <h3>Coupon Validity</h3>
          </div>
          <div class="panel-body">
            <div class="checkbox-group">
              <label for="isActiveCheck">
                <input type="checkbox" v-model="dataSet.isActive" id="isActiveCheck" />
                Activate Coupon
              </label>
            </div>

            <div class="form-group">
              <label for="startDate">Start Date<span class="req">*</span></label>
              <validation-provider rules="required" mode="passive" v-slot="{ errors }">
                <datetime type="date" value-zone="local" zone="local" v-model="dataSet.startDate" :auto="true" name="startDate" placeholder="mm-dd-yyyy"></datetime>
                <span class="invalid">{{ errors[0] }}</span>
              </validation-provider>
            </div>

            <div class="form-group">
              <label for="endDate">End Date<span class="req">*</span></label>
              <validation-provider rules="required" mode="passive" v-slot="{ errors }">
                <datetime type="date" value-zone="local" zone="local" v-model="dataSet.endDate" :min-datetime="dataSet.startDate" :auto="true" name="endDate" placeholder="mm-dd-yyyy"></datetime>
                <span class="invalid">{{ errors[0] }}</span>
              </validation-provider>
            </div>

            <div class="form-group">
              <label for="daysOfWeek">Days of the week</label>
              <v-select
                multiple
                v-model="dataSet.daysOfWeek"
                :options="daysOfTheWeek"
                label="label"
                :reduce="type => type.value"
                id="daysOfWeek">
              </v-select>
            </div>
          </div>
        </div>

        <div class="content-panel">
          <div class="panel-title">
            <h3>Discount Settings</h3>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <label for="discountValue">Amount off<span class="req">*</span></label>
              <validation-provider rules="required" v-slot="{ errors }">
                  <input type="number" id="discountValue" name="Discount Amount" v-model="dataSet.discount.value" />
                  <span class="invalid">{{ errors[0] }}</span>
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="usageType">Discount by<span class="req">*</span></label>
              <validation-provider rules="required" v-slot="{ errors }">
                <v-select
                  v-model="dataSet.discount.type"
                  :options="discountTypes"
                  label="label"
                  :reduce="type => type.value"
                  id="usageType">
                </v-select>
                <span class="invalid">{{ errors[0] }}</span>
              </validation-provider>
            </div>

            <div class="checkbox-group">
              <label for="discountEach">
                <input type="checkbox" v-model="dataSet.discount.perItem" id="discountEach" />
                Apply this discount to each ticket
              </label>
            </div>
          </div>
        </div>
        <div class="link-bar" v-if="dataRef">
          <button class="btn btn-text btn-delete" @click="deleteData()" title="Delete">
            <i class="fa-thin fa-trash"></i>
            Delete Coupon
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileUploader from '@/components/FileUploader';
import { mapGetters } from 'vuex';
import { Datetime } from 'vue-datetime';
import PurgeApi from "@/services/admin/purgeApi";

export default {
  name: 'Coupon',
  data() {
    return {
      purgeApi: new PurgeApi(),
      dataRef: this.$route.params.ref,
      couponId: '',
      eventList: [],
      showList: [],
      dataSet: {
        discount: {
          perItem: undefined,
          type: '',
          value: undefined
        }
      },
      loading: false,
      usageTypes: [
        {
          label: 'Single-use Coupon',
          value: 'single'
        },
        {
          label: 'Multi-use Coupon',
          value: 'multi'
        },
      ],
      discountTypes: [
        {
          label: 'Dollar amount',
          value: 'dollar'
        },
        {
          label: 'Percentage off',
          value: 'percentage'
        },
      ],
      minPurchaseTypes: [
        {
          label: 'Dollar amount',
          value: 'dollar'
        },
        {
          label: 'Tickets',
          value: 'tickets'
        },
      ],
      daysOfTheWeek: [
        {
          label: 'Monday',
          value: 'monday'
        },
        {
          label: 'Tuesday',
          value: 'tuesday'
        },
        {
          label: 'Wednesday',
          value: 'wednesday'
        },
        {
          label: 'Thursday',
          value: 'thursday'
        },
        {
          label: 'Friday',
          value: 'friday'
        },
        {
          label: 'Saturday',
          value: 'saturday'
        },
        {
          label: 'Sunday',
          value: 'sunday'
        }
      ]
    }
  },
  metaInfo() {
    let self = this;
    return {
      title: self.title
    }
  },

  computed: {
    title: function() {
      let self = this;

      if(self.dataRef) {
        return 'Edit Coupon'
      } else {
        return 'Add a Coupon'
      }
    },

    storageContext: function() {
      let self = this;

      return self.getCurrentPartner === 'watchcomedy.live' ? 'watchcomedylive' : `partners/${self.getCurrentPartner}`
    },

    ...mapGetters ({
      db: 'db',
      tinyApi: 'getTinyApi',
      isWCL: 'getIsWCL',
      getCurrentPartner: 'getCurrentPartner'
    })
  },

  components: {
    'FileUploader': FileUploader,
    Datetime: Datetime
  },

  async created() {
    let self = this;

    if(!self.dataRef) self.dataSet.daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
    self.getData();
    self.couponId = JSON.stringify(Date.now());
  },

  methods: {
    getData: async function() {
      let self = this;
      self.loading = true;

      let today = new Date().toLocaleDateString('en-CA');
      let eventsData;

      try {
        eventsData = await self.db.collection('events').where('endDate', '>=', today).get();
      } catch (err) { console.log(err); }

      self.eventList = eventsData.docs.map(doc => doc.data());

      if(self.dataRef) {
        let couponData = await self.db.collection('coupons').doc(self.dataRef).get();
        self.dataSet = couponData.data();
      }

      self.loading = false;
    },

    getShowList: async function() {
      let self = this;
      if(self.dataSet.event) {
        let event = self.eventList.find(event => event.id === self.dataSet.event.id);
        self.showList = event.shows;
      }
    },

    saveChanges: async function() {
      let self = this;
      let ref = self.dataRef ? self.dataRef : self.couponId;
      let success = await self.$refs.couponForm.validate();

      if(success) {
        if(self.dataRef) {
          try {
            let coupon = self.dataSet;
            coupon.startDate = coupon.startDate.substr(0, 10);
            coupon.endDate = coupon.endDate.substr(0, 10);
            await self.db.collection('coupons').doc(ref).update(self.dataSet);
            self.$notify({
              group: 'saved',
              title: 'Coupon Saved',
              type: 'success'
            });
          }
          catch (error) {
            self.$notify({
              group: 'saved',
              title: 'Error Saving - ' + error,
              type: 'error'
            });
          }
        }
        else {
          try {
            let coupon = self.dataSet;
            coupon.id = ref;
            coupon.description = self.dataSet.description || '';
            coupon.startDate = coupon.startDate.substr(0, 10);
            coupon.endDate = coupon.endDate.substr(0, 10);

            if(coupon.discount.perItem === undefined) coupon.discount.perItem = false;
            
            await self.db.collection('coupons').doc(ref).set(coupon)
            self.$notify({
              group: 'saved',
              title: 'Coupon Successfully Created',
              type: 'success'
            });
            await self.$router.push({ path: '/coupons/' + ref });
            await self.getData();
          }
          catch (error) {
            self.$notify({
              group: 'saved',
              title: 'Error Creating Coupon - ' + error,
              type: 'error'
            });
          }
        }
        await self.purgeApi.purgeUrl(self.getCurrentPartner, `/coupons/${self.dataRef}`);
      }
    },

    deleteData: function() {
      let self = this;
      self.$modal.show('dialog', {
        title: 'Delete Confirmation',
        text: 'Are you sure you want to delete this coupon?',
        buttons: [
          {
            title: 'Confirm',
            class: 'btn dialogConfirmBtn',
            default: true,
            handler: async () => {
              let confirmButton = document.querySelector('.dialogConfirmBtn');
              confirmButton.innerHTML = '<i class="fa-thin fa-spinner-third fa-spin"></i>';

              self.db.collection('coupons').doc(self.dataRef).delete()
              .then(function() {
                  self.$notify({
                    group: 'saved',
                    title: 'Coupon Deleted',
                    type: 'success'
                  });
                  self.$modal.hide('dialog');
                  self.$router.push({ name: 'Coupons'} )
              })
              .catch(function(error) {
                  self.$notify({
                    group: 'saved',
                    title: 'Error Deleting - ' + error,
                    type: 'error'
                  });
              });
            }
          },
          {
            title: 'Cancel',
            class: 'btn btn-sec',
            handler: () =>  { self.$modal.hide('dialog'); }
          }
        ]
      });
    }
  },

  watch: {
    'db': function() {
      let self = this;
      self.$router.push({ name: 'Coupons' });
    }
  }
}
</script>
