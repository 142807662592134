import Vue from 'vue'
import App from './App.vue'
import { store } from './store/store.js'
import router from './router'
import GlobalNav from './components/GlobalNav'
import ProfileBar from './components/ProfileBar'
import VueGoodTablePlugin from 'vue-good-table';
import CheckboxToggle from './components/CheckboxToggle.vue';
import vSelect from 'vue-select'
import Editor from '@tinymce/tinymce-vue';
import VueSlideoutPanel from 'vue2-slideout-panel';
import VTooltip from 'v-tooltip'
import Notifications from 'vue-notification'
import VModal from 'vue-js-modal'
import VueMeta from 'vue-meta'
import VueMoment from 'vue-moment'
import VueCookies from 'vue-cookies'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { auth } from "@/firebaseConfig";
import PartnerApi from "@/services/partnerApi";

extend('required', {
  ...required,
  message: 'This field is required'
});
extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match'
});

import './assets/css/stylesheet.css';
import 'vue-datetime/dist/vue-datetime.css';
import 'vue-select/dist/vue-select.css';
import 'vue-good-table/dist/vue-good-table.css';


Vue.component('GlobalNav', GlobalNav);
Vue.component('ProfileBar', ProfileBar);
Vue.component('CheckboxToggle', CheckboxToggle);
Vue.component('v-select', vSelect);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('wysiwyg-editor', Editor);

Vue.use(VModal, { scrollable: true, dialog: true } );
Vue.use(VueGoodTablePlugin);
Vue.use(Notifications);
Vue.use(VTooltip);
Vue.use(VueMeta);
Vue.use(VueMoment);
Vue.use(VueSlideoutPanel);
Vue.use(VueCookies);

Vue.config.productionTip = false;
let app = null;
let api = new PartnerApi();
auth.onAuthStateChanged(async (user) => {
  if (user) {
    let idToken = await user.getIdTokenResult();
    await store.dispatch("setCurrentUser", idToken);
    let partnerId = idToken.claims.role === 'su' ? sessionStorage.getItem('currentPartner') ? sessionStorage.getItem('currentPartner') : null : null;
    let partner = (await api.getPartner(partnerId)).data;
    await store.dispatch('setCurrentPartner', partner.partnerId);
    await store.dispatch('setCurrentPartnerData', partner.partnerData);
  }

  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app');
  }


});
