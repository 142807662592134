<template>
  <section class="global-nav" :class="{ 'mobile' : isMobileNav }">
    <div class="fixed">
      <div v-if="isSuperUser">
        
        <div class="logo">
          <img src='../assets/img/logo.png' alt="WatchComedy.live" />

          <button class="close" @click="hideMainNav" title="Close Menu">
            <i class="fal fa-times"></i>
          </button>
        </div>
        <ul class="nav">
          <li v-for="(item, itemIndex) in suNavItems" :key="itemIndex">
            
            <!-- Nav Item Without Children -->
            <router-link v-if="!item.children" :to="{ name: item.component }" @click="isOpen = -1">
              <i :class="item.icon"></i>
              <span class="text">{{ item.name }}</span>
            </router-link>

            <!-- Nav Item With Children -->
            <span v-if="item.children">
              <a :title="item.name" class="accordion" :class="{ 'open': isOpen === itemIndex }" @click="toggleSubNav(itemIndex)">
                <i :class="item.icon"></i>
                <span class="text">
                  {{ item.name }}
                  <i class="fa-thin fa-angle-down angle" v-if="isOpen !== itemIndex"></i>
                  <i class="fa-thin fa-angle-up angle" v-if="isOpen === itemIndex"></i>
                </span>
              </a>
              
              <ul class="subnav" v-if="isOpen === itemIndex">
                <li v-for="(child, childIndex) in item.children" :key="childIndex">
                  <router-link :to="{ name: child.component }">{{ child.name }}</router-link>
                </li>
              </ul>
            </span>
          </li>
        </ul>
      </div>

      <div v-else>
        <div class="logo">
          <img src='../assets/img/logo.png' alt="WatchComedy.live" />

          <button class="close btn-text" @click="hideMainNav" title="Close Menu">
            <i class="fal fa-times"></i>
          </button>
        </div>
        <ul class="nav">
          <li v-for="(item, itemIndex) in navItems" :key="itemIndex">
            
            <!-- Nav Item Without Children -->
            <router-link v-if="!item.children" :to="{ name: item.component }" @click="isOpen = -1">
              <i :class="item.icon"></i>
              <span class="text">{{ item.name }}</span>
            </router-link>

            <!-- Nav Item With Children -->
            <span v-if="item.children">
              <a :title="item.name" class="accordion" :class="{ 'open': isOpen === itemIndex }" @click="toggleSubNav(itemIndex)">
                <i :class="item.icon"></i>
                <span class="text">
                  {{ item.name }}
                  <i class="fa-thin fa-angle-down angle" v-if="isOpen !== itemIndex"></i>
                  <i class="fa-thin fa-angle-up angle" v-if="isOpen === itemIndex"></i>
                </span>
              </a>
              
              <ul class="subnav" v-if="isOpen === itemIndex">
                <li v-for="(child, childIndex) in item.children" :key="childIndex">
                  <router-link :to="{ name: child.component }">{{ child.name }}</router-link>
                </li>
              </ul>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GlobalNav',
  data() {
    return {
      navItems: [
        {
          name: 'Dashboard',
          component: 'Dashboard',
          icon: 'fal fa-gauge',
        },
        {
          name: 'Events',
          component: 'Events',
          icon: 'fal fa-calendar-day',
        },
        {
          name: 'Coupons',
          component: 'Coupons',
          icon: 'fal fa-tag',
        },
        {
          name: 'Comics',
          component: 'Comics',
          icon: 'fal fa-microphone-stand',
        },
        {
          name: 'Products',
          component: 'Products',
          icon: 'fal fa-shopping-cart',
        },
        {
          name: 'Season Tickets',
          icon: 'fal fa-tickets',
          children: [
            {
              name: 'Season Ticket Holders',
              component: 'SeasonTicketHolders',
            },
            {
              name: 'Season Ticket Reservations',
              component: 'SeasonTicketReservations',
            }
          ]
        },
        {
          name: 'Sales',
          icon: 'fal fa-cash-register',
          children: [
            {
              name: 'Ticket Orders',
              component: 'Orders',
            },
            {
              name: 'Product Orders',
              component: 'ProductOrders',
            },
            {
              name: 'Reports',
              component: 'Reports',
            }
          ]
        },
        {
          name: 'Website',
          icon: 'fal fa-sitemap',
          children: [
            {
              name: 'Pages',
              component: 'Pages',
            },
            {
              name: 'Promotions',
              component: 'Promotions',
            },
            {
              name: 'Articles',
              component: 'Articles',
            },
            {
              name: 'Display',
              component: 'Display',
            },
            {
              name: 'Menus',
              component: 'Menus',
            },
            {
              name: 'Messaging',
              component: 'Messaging',
            }
          ]
        },
        {
          name: 'Customers',
          icon: 'fal fa-share-alt-square',
          children: [
            {
              name: 'Support Requests',
              component: 'SupportRequests',
            },
            {
              name: 'Insider Emails',
              component: 'InsiderEmails',
            }
          ]
        },
        {
          name: 'Settings',
          icon: 'fal fa-cogs',
          children: [
            {
              name: 'Users',
              component: 'Users',
            },
            {
              name: 'My Profile',
              component: 'Profile',
            },
            {
              name: 'Venue Information',
              component: 'VenueInfo',
            }
          ]
        },
      ],
      suNavItems: [
        // {
        //   name: 'Dashboard',
        //   component: 'Dashboard',
        //   icon: 'fa-thin fa-gauge'
        // },
        {
          name: 'Events',
          component: 'Events',
          icon: 'fal fa-calendars'
        },
        {
          name: 'Comics',
          component: 'Comics',
          icon: 'fa-thin fa-masks-theater'
        },
        {
          name: 'Venues',
          component: 'Venues',
          icon: 'fa-thin fa-microphone-stand'
        },
        {
          name: 'Articles',
          component: 'Articles',
          icon: 'fa-thin fa-newspaper'
        },
        {
          name: 'Settings',
          icon: 'fal fa-cogs',
          children: [
            {
              name: 'Users',
              component: 'Users',
            },
            {
              name: 'My Profile',
              component: 'Profile',
            }
          ]
        },
      ],
      windowWidth: window.innerWidth,
      isOpen: -1,
      isMobileNav: false
    }
  },

  methods: {
    onResize: function() {
      let self = this;

      self.windowWidth = window.innerWidth;

      let nav = document.querySelector(".global-nav");
      
      if(self.windowWidth < 900) {
        self.isMobileNav = true;
        nav.style.display = 'none';
      } else {
        self.isMobileNav = false;
        nav.style.display = 'block';
      }
    },

    hideMainNav: function() {
      let self = this;
      let nav = document.querySelector(".global-nav");
      nav.style.display = 'none';
    },

    toggleSubNav: function(index) {
      let self = this;
      if(self.isOpen !== index) {
        self.isOpen = index;
      } else {
        self.isOpen = -1
      }
    }
  },

  mounted() {
    let self = this;
    self.onResize();
    self.$nextTick(() => {
        window.addEventListener('resize', self.onResize);
    })
  },

  beforeDestroy() { 
    let self = this;
    window.removeEventListener('resize', self.onResize); 
  },

  watch: {
    $route (to, from){
        let self = this;
        if(self.isMobileNav) {
          self.hideMainNav();
        }
    }
  },

  computed: {
    ...mapGetters (
      {
        currentUser : 'getCurrentUser',
        isWCL : 'getIsWCL'
      }
    ),

    isSuperUser: function() {
      let self = this;
      return self.isWCL;
    }
  }
}
</script>