<template>
  <div class="page-body">

    <div class="page-title-bar sticky">
      <h1>{{ title }}</h1>

      <button class="btn" @click="updateSettings()" title="Save Changes">Save Changes</button>
    </div>

    <p v-if="loading">Loading</p>

    <div v-if="!loading">
      <ValidationObserver ref="settingsForm">
        <ul class="form-tabs">
          <li
            v-for="(tab, tabIndex) in formTabs"
            :key="tabIndex"
            @click="currentTab = tab"
            :class="{ 'active' : currentTab === tab}"
            >
            <h2>{{tab}}</h2>
          </li>
        </ul>
        <div class="settings-form">
          <div class="content-panel" v-show="currentTab === 'Ticket Sales'">

            <div class="panel-title">
              <h3>Ticket Confirmation Page</h3>
            </div>

            <div class="panel-body p-margin">
              <p>Custom content to be displayed at the bottom of the <strong>Ticket Purchase Confirmation Page / Email</strong></p>

              <div class="form-group">
                <wysiwyg-editor
                  v-model="messaging.tickets.confirmation"
                  class="html-editor"
                  :api-key="tinyApi"
                  toolbar="undo redo | bold italic underline strikethrough code | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl"
                  plugins="paste importcss searchreplace autolink code visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars"
                  :init="{
                    menubar: false,
                    setup(editor) {
                      editor.on('init', function() {
                        editor.setContent(editor.getContent());
                      });
                    },
                    content_css:'https://unpkg.com/tailwindcss@2.2.19/dist/tailwind.min.css'
                  }">
                </wysiwyg-editor>
              </div>
            </div>
          </div>

          <div class="content-panel" v-show="currentTab === 'Product Sales'">

            <div class="panel-title">
              <h3>Product Confirmation Page</h3>
            </div>

            <div class="panel-body p-margin">
              <p>Custom content to be displayed at the bottom of the <strong>Product Purchase Confirmation Page / Email</strong></p>

              <div class="form-group">
                <wysiwyg-editor
                  v-model="messaging.products.confirmation"
                  class="html-editor"
                  :api-key="tinyApi"
                  toolbar="undo redo | bold italic underline strikethrough code | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl"
                  plugins="paste importcss searchreplace autolink code visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars"
                  :init="{
                    menubar: false,
                    setup(editor) {
                      editor.on('init', function() {
                        editor.setContent(editor.getContent());
                      });
                    },
                    content_css:'https://unpkg.com/tailwindcss@2.2.19/dist/tailwind.min.css'
                  }">
                </wysiwyg-editor>
              </div>
            </div>
          </div>

          <div class="content-panel" v-show="currentTab === 'Season Tickets'">

            <div class="panel-title">
              <h3>Season Ticket Confirmation Page / Email</h3>
            </div>

            <div class="panel-body p-margin">
              <p>Custom content to be displayed at the bottom of the <strong>Season Ticket Purchase Confirmation Page / Email</strong></p>

              <div class="form-group">
                <wysiwyg-editor
                  v-model="messaging.seasonTickets.confirmation"
                  class="html-editor"
                  :api-key="tinyApi"
                  toolbar="undo redo | bold italic underline strikethrough code | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl"
                  plugins="paste importcss searchreplace autolink code visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars"
                  :init="{
                    menubar: false,
                    setup(editor) {
                      editor.on('init', function() {
                        editor.setContent(editor.getContent());
                      });
                    },
                    content_css:'https://unpkg.com/tailwindcss@2.2.19/dist/tailwind.min.css'
                  }">
                </wysiwyg-editor>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>

  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import PartnerApi from "@/services/partnerApi";

export default {
  name: 'Settings',
  data() {
    return {
      title: 'Customer Messaging',
      partnerApi: null,
      messaging: {
        tickets: {
          confirmation: ''
        },
        products: {
          confirmation: ''
        },
        seasonTickets: {
          confirmation: ''
        }
      },
      loading: false,
      currentTab: 'Ticket Sales',
      formTabs: [
        'Ticket Sales',
        'Product Sales',
        'Season Tickets'
      ]
    }
  },
  metaInfo: {
    title: 'Messaging Settings'
  },
  computed: {
    ...mapGetters ({
      db: 'db',
      tinyApi: 'getTinyApi',
      currentPartner: 'getCurrentPartner'
    })
  },

  components: { },

  methods: {
    getSettings: async function() {
      let self = this;
      self.loading = true;

      let messaging = await self.db.get();
      self.messaging = messaging.data().messaging;

      if(!self.messaging.seasonTickets) {
        self.messaging.seasonTickets = {
          confirmation: ''
        }
      }

      self.loading = false;
    },

    updateSettings: async function(ref) {
      let self = this;
      let messaging = self.messaging;

      let success = self.$refs.settingsForm.validate();

      if(success) {
        try {
          await self.db.update({ messaging });

          self.$notify({
            group: 'saved',
            title: 'Settings Updated',
            type: 'success'
          });
        }
        catch (err) {
          self.$notify({
            group: 'saved',
            title: 'Error Updating Settings',
            type: 'error'
          });
        }
      }
    }

  },

  async created() {
    let self = this;
    self.partnerApi = new PartnerApi();
    self.getSettings();
  },

  watch: {
    'db': function() {
      let self = this;
      self.getSettings();
    }
  }
}
</script>
