<template>
  <section class="profile-bar">
    <div class="menu">
      <button class="btn btn-text" @click="toggleMainNav">
        <i class="fa fa-bars"></i>
      </button>
    </div>

    <div class="partner-selector" v-if="isSuperUser">
      <v-select
        :options="partnerList"
        :reduce="item => item.id"
        label="id"
        :clearable="false"
        @input="setCurrentPartner"
        v-model="selectedPartner">
      </v-select>
    </div>
    <div class="right">
      <div class="profile" v-click-outside="hideProfileMenu">
        <div class="selector" @click="showProfileMenu = !showProfileMenu">
          <div class="avatar">
            <i class="fa-thin fa-user"></i>
          </div>
          <div class="text">
            <div class="details">
              <span class="name">{{getCurrentUser.claims.name}}</span>
              <span class="email">{{getCurrentUser.claims.email}}</span>
            </div>
            <i class="fa-thin fa-angle-down"></i>
          </div>

          <div class="profile-menu" v-if="showProfileMenu">
            <ul>
              <li>
                <router-link :to="{ name: 'Profile' }" title="Profile">
                  <i class="fa-thin fa-user"></i>
                  Profile
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Logout' }" title="Logout">
                  <i class="fa-thin fa-sign-out"></i>
                  Logout
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { fs } from '@/firebaseConfig';
import { mapGetters } from 'vuex';
import PartnerApi from "@/services/partnerApi";

export default {
  name: 'ProfileBar',
  data() {
    return {
      api: new PartnerApi(),
      showProfileMenu: false,
      partnerList: [],
      selectedPartner: '',
      showScanner: false,
      useFlash: false
    }
  },

  methods: {
    onDecode: function (val) {
      this.showScanner = false;
      //wcl:localhost/ticket/1639847362055/rusPxPb15Li8ffftRvuay7vGlbYnkG/1639847984445
      if (!val.startsWith('wcl:')) return;
      let split = val.substring(4).split('/');
      if (split.length !== 5) return;
      if (split[0] !== this.getCurrentPartner) return;
      let partner = split[0];
      if (split[1] !== 'ticket' && split[1] !== 'order') return;
      let type = split[1];
      this.$router.push(`${(type === 'ticket' ? 'orders' : 'product-orders')}/${split[4]}`);
      //console.log(split);
    },
    paintOutline: function (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [ firstPoint, ...otherPoints ] = detectedCode.cornerPoints

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
    toggleMainNav: function() {
      let self = this;
      let nav = document.querySelector(".global-nav");
      nav.style.display = nav.style.display === 'none' || nav.style.display === '' ? 'block' : 'none' ;
    },

    hideProfileMenu: function() {
      let self = this;
      self.showProfileMenu = false;
    },

    getPartners: async function() {
      let self = this;
      let partners = await fs.collection('partners').get();
      self.partnerList = partners.docs.map(doc => ({ id: doc.id }));
      self.partnerList.splice(0, 0, {id: 'watchcomedy.live'});
    },

    setCurrentPartner: async function() {
      let self = this;
      let partner = (await fs.collection('partners').doc(self.selectedPartner).get()).data();

      if (self.getCurrentUser.claims.role === 'su') {
        window.sessionStorage.setItem('currentPartner', self.selectedPartner);
      }
      self.$cookies.set('x-partnerId', self.selectedPartner);
      self.$store.dispatch('setCurrentPartner', self.selectedPartner);
      self.$store.dispatch('setCurrentPartnerData', partner);
      self.$store.dispatch('unbindAllData');
    },
  },

  async mounted() {
    let self = this;
    self.selectedPartner = self.getCurrentPartner;
    if (self.isSuperUser) await self.getPartners();
  },

  watch: {
    'getCurrentPartner': function (value) {
      let self = this;
      self.selectedPartner = value;
    }
  },

  directives: {
    'click-outside': {
      bind: function (el, binding, vnode) {
          el.clickOutsideEvent = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
              vnode.context[binding.expression](event);
          }
          };
          document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind: function (el) {
          document.body.removeEventListener('click', el.clickOutsideEvent)
      },
    }
  },

  computed: {
    ...mapGetters ({
      getCurrentUser: 'getCurrentUser',
      getCurrentPartner: 'getCurrentPartner'
    }),

    isSuperUser: function() {
      let self = this;
      if(self.getCurrentUser.claims.role === 'su') {
        return true;
      } else { return false }
    }
  }
}
</script>
