<template>
  <section class="page-body">

    <div class="page-title-bar sticky">
      <h1>{{ title }}</h1>
    </div>
    
    <div class="content-panel">
      <div class="panel-body no-title">
        <div class="data-table">
          <div class="data-table-search">
            <div class="actions">
              <span class="search-icon"><i class="fa-thin fa-search"></i></span>
              <input 
                class="search-input" 
                type="text" 
                :placeholder="'Search Season Ticket Holders'" 
                v-model="tableSearchTerm" />
            </div>
          </div>

          <vue-good-table
              :columns="columns"
              :rows="gridList"
              @on-row-click="viewPage"
              styleClass="vgt-table bookings-table"
              :sort-options="{
                  enabled: true,
                  initialSortBy: { field: 'createdAt', type: 'desc' }
              }"

              :search-options="{
                  enabled: true,
                  skipDiacritics: true,
                  externalQuery: tableSearchTerm
              }"

              :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 50,
                  position: 'bottom',
                  dropdownAllowAll: false,
                  nextLabel: 'next',
                  prevLabel: 'prev',
                  rowsPerPageLabel: 'Rows per page',
                  ofLabel: 'of',
                  pageLabel: 'page', // for 'pages' mode
                  allLabel: 'All',
              }"
          >

              <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'createdAt'">
                    {{ props.row.createdAt | moment("ddd, MMM Do YYYY")}}
                  </span>

                  <span v-else-if="props.column.field === 'packages'">
                    {{ props.row.packages.length }}
                  </span>
              </template>

          </vue-good-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SeasonTicketReservations',
  data() {
    return {
      tableSearchTerm: '',
      columns: [
        {
          label: 'Name',
          field: 'name',
          tdClass: 'bold'
        },
        {
          label: 'Email Address',
          field: 'email'
        },
        {
          label: 'Created Date',
          field: 'createdAt'
        },
        {
          label: '# of Packages',
          field: 'packages'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
        db : 'db',
        gridList: 'getSeasonTicketHoldersData'
    }),

    title: function() {
      let self = this;
      return 'All Season Ticket Holders'
    },
  },

  metaInfo: {
    title: 'All Season Ticket Holders'
  },

  async mounted() {
    let self = this;
    await self.getGridData();
  },

  methods: {
    getGridData: async function() {
      let self = this;

      await self.$store.dispatch('bindSeasonTicketHoldersData');
      await self.$store.dispatch('bindEventsData');
    },

    viewPage: function(params) {
      let self = this;
      let id = params.row.userId;
      self.$router.push({name: 'EditSeasonTicketHolder', params:{ref:id}})
    },
  },

  watch: {
    'db': function() {
      let self = this;
      
      self.getGridData(true);
    }
  }
}
</script>
